<template>
  <svg
    width="14"
    height="24"
    viewBox="0 0 14 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.14 7.17C13.0464 7.06341 12.9119 7.00161 12.77 7H9V5.59C9 5.31 9.06 4.99 9.51 4.99H12.51C12.6434 4.99569 12.7721 4.94051 12.86 4.84C12.9482 4.74856 12.9982 4.627 13 4.5V0.5C13 0.223858 12.7761 0 12.5 0H8.17C3.37 0 3 4.1 3 5.35V7H0.5C0.223858 7 0 7.22386 0 7.5V11.5C0 11.7761 0.223858 12 0.5 12H3V23.5C3 23.7761 3.22386 24 3.5 24H8.5C8.77614 24 9 23.7761 9 23.5V12H12.35C12.6077 12.0013 12.8242 11.8065 12.85 11.55L13.27 7.55C13.2817 7.41073 13.2345 7.27293 13.14 7.17Z"
      fill="#1877F2"
    />
  </svg>
</template>

<script>
  export default {
    name: 'FacebookLogo',
  };
</script>
