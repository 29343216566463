<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9253 3.88802C15.8797 3.86866 15.827 3.87895 15.792 3.91402L9.294 10.4107C8.57812 11.1262 7.41788 11.1262 6.702 10.4107L0.206668 3.91402C0.171879 3.87861 0.118879 3.86827 0.0733346 3.88802C0.0286427 3.90727 -0.000225015 3.95136 1.32137e-06 4.00002V12C1.32137e-06 12.7364 0.596955 13.3334 1.33333 13.3334H14.6667C15.403 13.3334 16 12.7364 16 12V4.00002C16.0002 3.95098 15.9707 3.90671 15.9253 3.88802Z"
      fill="#314070"
    />
    <path
      d="M7.40977 9.70402C7.73553 10.0288 8.26268 10.0288 8.58844 9.70402L15.0458 3.24669C15.1349 3.15753 15.1661 3.02569 15.1264 2.90602C15.0531 2.68669 14.8491 2.66669 14.6664 2.66669H1.3331C1.14977 2.66669 0.944437 2.68669 0.872437 2.90602C0.832731 3.02569 0.863948 3.15753 0.953104 3.24669L7.40977 9.70402Z"
      fill="#314070"
    />
  </svg>
</template>

<script>
  export default {
    name: 'EmailIcon',
  };
</script>
