<template>
  <ion-page>
    <ion-content  id="main-content" :fullscreen="true" :class="isDark ? 'dark-theme' : ''">
      <div v-if="isDark">
        <div class="image-gradient"></div>
        <img src="@/assets/images/hands_raised_image.png" class="background-image"/>
      </div>
      <div class="container">
        <h1 >Welcome Back</h1>
        <p class="subtitle">Log in now to access your Tithe.ly account!</p>
        <div class="social-buttons">
          <Button variant="secondary" text="Sign In with Facebook">
            <template #icon>
              <FacebookLogo />
            </template>
          </Button>
          <Button variant="secondary" text="Sign In with Google">
            <template #icon>
              <GoogleLogo />
            </template>
          </Button>
          <Button variant="secondary" text="Sign In with Emailed Link">
            <template #icon>
              <EmailIcon />
            </template>
          </Button>
        </div>
        <div class="email-text-div">
          <div class="line"></div>
          <p class="email-text">or login with email and password</p>
          <div class="line"></div>
        </div>
        <Button text="LOGIN" @click="$router.push({ name: 'Login' })"/>
      </div>
    </ion-content>
     <Footer />
  </ion-page>
</template>

<script>
  import ViewMixin from '@/mixins/views';
  import { IonContent, IonPage } from '@ionic/vue';

  import { defineComponent } from 'vue';
  import Button from '@/components/shared/buttons/Button.vue';

  import FacebookLogo from '@/components/shared/icons/FacebookLogo.vue';
  import GoogleLogo from '@/components/shared/icons/GoogleLogo.vue';
  import EmailIcon from '@/components/shared/icons/EmailIcon.vue';

  import Footer from '@/components/layout/Footer';
  import { mapState } from 'vuex';

  export default defineComponent({
    name: 'Login',
    components: {
      IonContent,
      IonPage,
      Footer,
      Button,
      FacebookLogo,
      GoogleLogo,
      EmailIcon,
    },
    mixins:[ViewMixin],
    ionViewDidEnter(){
      this.incrementComponentKeyCounter()
    },
    computed: {
      ...mapState({
        theme: state => state.theme
      }),

      isDark() {
        return this.theme == 'dark';
      }
    }
  });
</script>

<style scoped lang="scss">
  ion-content {
    .container {
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;
      padding-bottom: 80px;
      h1{
        font-size: 75px;
        margin-top: 80px;
        margin-bottom: 0;
      }

      .subtitle{
        color: #4064c0;
        font-size: 22px;
        font-weight: 500;
      }
      .social-buttons {
        margin-top: 26px;
      }

      .email-text-div {
        margin-top: 50px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .email-text {
          color: #67749e;
          margin-left: 43px;
          margin-right: 43px;
        }

        .line {
          height: 1px;
          width: 140px;
          background: #67749e;
        }
      }
    }    
  }

  ion-content.dark-theme {
    --background: #181F36;

    .background-image {
      position: absolute;
      top: 0;
      height: 50%;
      object-fit: cover;
      padding-bottom: 35px;
    }

    .image-gradient {
      position: absolute;
      top: 0;
      width: 100%;
      height: 50%;
      z-index: 1;

      background: -webkit-linear-gradient(bottom, rgb(24, 31, 54) 0%, rgb(24, 31, 54) 16%, rgba(24, 31, 54, 0.54) 100%);
      background: -o-linear-gradient(bottom, rgb(24, 31, 54) 0%, rgb(24, 31, 54) 16%, rgba(24, 31, 54, 0.54) 100%);
      background: -ms-linear-gradient(bottom, rgb(24, 31, 54) 0%, rgb(24, 31, 54) 16%, rgba(24, 31, 54, 0.54) 100%);
      background: -moz-linear-gradient(bottom, rgb(24, 31, 54) 0%, rgb(24, 31, 54) 16%, rgba(24, 31, 54, 0.54) 100%);
      background: linear-gradient(to top, rgb(24, 31, 54) 0%, rgb(24, 31, 54) 16%, rgba(24, 31, 54, 0.54) 100%);
    }

    .container {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      z-index: 2;

      h1{
        color: #ffffff;
        font-size: 75px;
        margin-top: 80px;
        margin-bottom: 0;
      }

      .subtitle{
        color: #4064c0;
        font-size: 22px;
        font-weight: 500;
      }

      .social-buttons {
        margin-top: 26px;
      }

      .email-text-div {
        margin-top: 50px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .email-text {
          color: #B8C0D9;
          margin-left: 43px;
          margin-right: 43px;
        }

        .line {
          height: 1px;
          width: 140px;
          background: #67749e;
        }
      }
    }
  }
    @media (max-height:768px) and (min-height:720px) {
     ion-content {
       .container {
         h1{
        font-size: 65px;
      }

      .subtitle{
        font-size: 15px;
      }
      .social-buttons {
        margin-top: 0px;
      }
         .email-text-div {
            margin-top: 10px;
            margin-bottom: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
         }
       }
     }
   }
    @media screen and (max-height: 540px) {
     ion-content {
       .container {
         margin-bottom: 60px;
         h1 {
           margin-top: 15px;
           font-size: 40px;
         }
         .subtitle{
           font-size: 17px;
           margin: 0px !important;
         }
         .email-text-div {
            margin-top: 30px;
            margin-bottom: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;

            .email-text {
              color: #67749e;
              margin-left: 43px;
              margin-right: 43px;
            }
          }
       }
     }
   }
   @media only screen and (orientation: portrait) {
   ion-content {
    .container{
      height: 90vh;
       display: flex;
       justify-content: center;
       align-items: center;
      overflow: hidden;
    } 
   } 
  }
</style>
