<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.34566 7.75387C6.5442 7.90333 6.82901 7.87912 6.99732 7.69849C8.2504 6.34783 10.0345 5.56582 11.9149 5.543C13.7953 5.52019 15.5993 6.25867 16.8873 7.57849C16.9773 7.66586 17.0998 7.71501 17.2275 7.71501C17.3553 7.71501 17.4778 7.66586 17.5677 7.57849L20.3661 5.04926C20.4568 4.96259 20.5078 4.84463 20.5078 4.72156C20.5078 4.5985 20.4568 4.48053 20.3661 4.39387C18.1315 2.10892 14.9953 0.844496 11.7372 0.9149C8.47907 0.985304 5.40494 2.38393 3.27899 4.7631C3.19001 4.86306 3.14959 4.99486 3.16789 5.12534C3.1862 5.25582 3.26149 5.37256 3.37482 5.44618L6.34566 7.75387Z"
      fill="#EB4335"
    />
    <path
      d="M5.52153 14.5754C5.66334 14.4526 5.71604 14.2604 5.65569 14.0862C5.18182 12.7518 5.18182 11.3036 5.65569 9.96925C5.72026 9.78579 5.65902 9.58302 5.50236 9.46156L2.36861 7.05232C2.25519 6.9653 2.10697 6.93243 1.96546 6.96292C1.82395 6.9934 1.70439 7.08397 1.64028 7.20925C0.082563 10.326 0.135798 13.9667 1.78403 17.04C1.85249 17.1672 1.97837 17.2565 2.12476 17.2818C2.27114 17.3071 2.42142 17.2655 2.53153 17.1692L5.52153 14.5754Z"
      fill="#FBBC05"
    />
    <path
      d="M23.4997 10.6154C23.4997 10.3605 23.2851 10.1539 23.0205 10.1539H13.4372C13.1725 10.1539 12.958 10.3605 12.958 10.6154V14.3077C12.958 14.5626 13.1725 14.7693 13.4372 14.7693H18.0755C17.7933 15.3327 17.425 15.8523 16.983 16.3108C16.8184 16.4865 16.8184 16.7536 16.983 16.9293L19.5418 19.6985C19.6324 19.7917 19.759 19.8446 19.8915 19.8446C20.0241 19.8446 20.1507 19.7917 20.2413 19.6985C22.1913 17.7765 23.3493 15.2373 23.4997 12.5539V10.6154Z"
      fill="#4285F4"
    />
    <path
      d="M15.3731 17.9631C15.2329 17.8131 15.0077 17.7682 14.8173 17.8523C12.1683 19.0538 9.01502 18.4426 7.05476 16.3477C6.86844 16.1718 6.57025 16.1718 6.38392 16.3477L3.50892 18.8215C3.41821 18.9082 3.36719 19.0262 3.36719 19.1492C3.36719 19.2723 3.41821 19.3903 3.50892 19.4769C7.18129 23.3681 13.1879 24.236 17.8839 21.5538C18.0064 21.4837 18.0902 21.3647 18.1124 21.2291C18.1346 21.0935 18.093 20.9554 17.9989 20.8523L15.3731 17.9631Z"
      fill="#34A853"
    />
  </svg>
</template>

<script>
  export default {
    name: 'GoogleLogo',
  };
</script>
